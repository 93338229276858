import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Loading from './components/Loading/Loading';
import Advant from './components/Advant/Advant';
import Service from './components/Sevice/Sevice';
import Price from './components/Price/Price';
import Mobile from './components/Mobile/Mobile';
import Contacts from './components/Contacts/Contacts';
import AppleModal from './components/AppleModal/AppleModal';
import {motion} from 'framer-motion'
import AndroidModal from './components/AndroidModal/AndroidModal';



function App() {
  const [load,setLoad] = useState(true)
  const [apple, setApple] = useState(false)
  const [android, setAndroid] = useState(false)

  return (
    <div className="App">
    <motion.div initial={{opacity:0}} whileInView={{opacity:1}} >
      {apple&&<AppleModal setApple={setApple}/>}
      {android&&<AndroidModal setAndroid={setAndroid} />}
      <Header/>
      <Hero/>
      <Advant/>
      <Service/>
      <Price/>
      <Mobile setApple={setApple} setAndroid={setAndroid}/>
      <Contacts/>
    </motion.div>
    {load&&<Loading setLoad={setLoad}/>}
    <a className='webHub' href='https://web-hub.pro'>Создание сайтов под ключ, лендинг, интернет магазин, корпоративный сайт</a>
    </div>
  );
}

export default App;
