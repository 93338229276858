import css from './Hero.module.css'
import circle from './img/circle11.png'
import circle1 from './img/circle12.png'
import circle2 from './img/circle2.png'
import koleso from './img/koleso.png'
import car from './img/car.png'
import {motion} from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'

const Hero = () => {
    const carRef = useRef()
    const koleso1Ref = useRef()
    const koleso2Ref = useRef()
    const header1Ref = useRef()
    const header2Ref = useRef()
    
    
    useEffect(()=>{

        setTimeout(()=>{
            gsap.to(carRef.current,{x:-1300,duration:0})
            gsap.to(koleso1Ref.current,{rotate:-140,duration:0})
            gsap.to(koleso2Ref.current,{rotate:-140,duration:0})
            gsap.to(header1Ref.current,{opacity:0,duration:0})
            gsap.to(header2Ref.current,{opacity:0,duration:0})

            gsap.to(carRef.current,{x:0,duration:1})
            gsap.to(koleso1Ref.current,{rotate:0,duration:1})
            gsap.to(koleso2Ref.current,{rotate:0,duration:1})
            gsap.to(header1Ref.current,{opacity:.8,duration:1,delay:1})
            gsap.to(header2Ref.current,{opacity:.8,duration:1,delay:2})
        },[3000])

    },[])

    
  return (
<section className={css.container} id='hero'>
    <img  src={circle} className={css.circle}/>
    <img  src={circle1} className={css.circle}/>
    <img  src={circle2} className={css.circle2}/>
    <div ref={carRef}   className={css.carContainer}>
        <div className={css.carRelative}>
            <img  src={car} className={css.car}/>
            <img ref={koleso1Ref} src={koleso} className={css.koleso} />
            <img ref={koleso2Ref} src={koleso} className={css.koleso2} />
        </div>
    </div>

    <h1 ref={header1Ref}  className={css.header}>Fresh Driver</h1>
    <h2 ref={header2Ref}  className={css.header2}>Доверьте свой путь профессионалу!</h2>
    <p className={css.description}>
        <b>Трезвый водитель</b> - ваш надежный партнер в безопасных поездках! Мы предоставляем качественные услуги по доставке вас и вашего автомобиля в любое место назначения. Наши опытные и профессиональные водители гарантируют вашу безопасность и комфорт на дороге!
    </p>

</section>
  )
}

export default Hero