import { useEffect, useState } from 'react';
import css from './AndroidModal.module.css'
import img1 from './img/1.png'
import img11 from './img/11.png'
import img2 from './img/2.png'
import img22 from './img/22.png'
import img3 from './img/3.png'
import img33 from './img/33.png'
import img4 from './img/4.png'
import img44 from './img/44.png'
import stroke from './img/stroke.png'
import {motion} from 'framer-motion'

const AndroidModal = ({setAndroid}) => {
    const [mob, setMob] = useState(true)
    useEffect(()=>{
        if(window.screen.width<=768){
            document.body.classList.add('popup-open');
            setMob(true)
        }else{
            setMob(false)  
        }
        
        return ()=>{
            document.body.classList.remove('popup-open');
        }
    },[])
  return (
<motion.section initial={{opacity:0}} whileInView={{opacity:1}} className={css.container} onClick={()=> setAndroid(false)}>
    <motion.div initial={{opacity:0}} whileInView={{opacity:1}} className={css.modalWind} onClick={e => e.stopPropagation()}> 
    <div className={css.close} onClick={()=>setAndroid(false)}>x</div>
        <h2 className={css.header}>
            Для установки приложения необходимо использовать браузер Chrome
        </h2>
        <div className={css.imgContainer}>
            <img src={!mob?img1:img11} className={css.img}/>
            <img src={stroke} className={css.stroke}/>
            <img src={!mob?img2:img22} className={css.img}/>
            <img src={stroke} className={css.stroke}/>
            <img src={!mob?img3:img33} className={css.img}/>
            <img src={stroke} className={css.stroke}/>
            <img src={!mob?img4:img44} className={css.img}/>
        </div>
    </motion.div>
</motion.section>
  )
}

export default AndroidModal
