import { useEffect, useRef } from 'react';
import css from './Loading.module.css'
import gsap from 'gsap';

const Loading = ({setLoad}) => {
  const loadRef = useRef()

  useEffect(()=>{
        document.body.classList.add('popup-open');
        setTimeout(()=>{
          gsap.to(loadRef.current,{opacity:0})
          setTimeout(()=>{
            setLoad(false)
          },[500])
        },[3000])
    return ()=>{
        document.body.classList.remove('popup-open');
    }
},[])
  return (
<section ref={loadRef} className={css.container}>
<div className={css.load}>
  <div>G</div>
  <div>N</div>
  <div>I</div>
  <div>D</div>
  <div>A</div>
  <div>O</div>
  <div>L</div>
</div>
</section>

  )
}

export default Loading
