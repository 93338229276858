import css from './Mobile.module.css'
import mob from './img/mob.png'
import apple from './img/apple.png'
import android from './img/android.png'


const Mobile = ({setApple,setAndroid}) => {
  return (
<section className={css.container}>
    <div className={css.textContainer}>
        <h2 className={css.header}>Мобильное приложение</h2>
        <h3 className={css.header1}>“Fresh Driver”</h3>
        <div className={css.buttonContainer}>
            <div className={css.button} onClick={()=>setApple(true)}>
                <img src={apple} className={css.apple}/>
                <span className={css.buttonType} >Apple</span>
            </div>
            <div className={css.button} onClick={()=>setAndroid(true)}>
                <img src={android} className={css.apple}/>
                <span className={css.buttonType} >Android</span>
            </div>
        </div>
    </div>
    <div className={css.imgContainer}>
        <img  src={mob} className={css.mobImg}/>
    </div>
</section>
  )
}

export default Mobile