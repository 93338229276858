import css from './Advant.module.css'
import img1 from './img/1.png'
import img2 from './img/2.png'
import img3 from './img/3.png'
import img4 from './img/4.png'


const Advant = () => {
  return (
<section className={css.container}>
    <h2 className={css.header}>Наши преимущества</h2>
    
    <div className={css.card}>
        <div className={css.textContainer}>
            <span><b>Безопасность превыше всего:</b> Наша команда водителей - профессионалы своего дела, которые предоставляют безопасные и надежные услуги!</span>
        </div>
        <div className={css.imgContainer}>
            <img src={img1} className={css.imgRight} />
        </div>
    </div>
    <div className={css.card2}>
    <div className={css.imgContainer2}>
            <img src={img2} className={css.imgRight} />
        </div>
        <div className={css.textContainer2}>
            <span><b>Доступность 24/7: </b> Мы доступны круглосуточно, чтобы быть рядом в тот момент, когда вам нужно вернуться домой в безопасности!</span>
        </div>
    </div>
    <div className={css.card}>
        <div className={css.textContainer}>
            <span><b>Комфорт и удобство: </b>Мы стремимся обеспечить ваш комфорт и удобство во время наших поездок, чтобы каждая из них была приятным опытом!</span>
        </div>
        <div className={css.imgContainer}>
            <img src={img3} className={css.imgRight} />
        </div>
    </div>
    <div className={css.card2}>
    <div className={css.imgContainer2}>
            <img src={img4} className={css.imgRight} />
        </div>
        <div className={css.textContainer2}>
            <span><b>Оперативность: </b>Время прибытия водителя 30 - 40 минут! (В выходные и праздничные дни возможно увеличение времени прибытия водителя)
</span>
        </div>
    </div>
</section>
  )
}

export default Advant
