import css from './Sevice.module.css'
import {Link} from 'react-scroll'

const Sevice = () => {
  return (
<section className={css.container} id='service'>
    <h2 className={css.header}>Услуги, которые мы оказываем</h2>
    <div className={css.cardContainer}>
        <Link spy={true} smooth={true} offset={-150} duration={1500} to="price1" className={css.card}>
        Трезвый водитель
        </Link>
        <Link spy={true} smooth={true} offset={-150} duration={1500} to="price2" className={css.card}>
        Водитель на день
        </Link>
        <Link spy={true} smooth={true} offset={-150} duration={1500} to="price2" className={css.card}>
        Перегон авто в любую точку России
        </Link>
        <Link spy={true} smooth={true} offset={-150} duration={1500} to="price2" className={css.card}>
        Доставка продуктов
        </Link>
    </div>
</section>
  )
}

export default Sevice
