import { useState } from 'react'
import css from './Header.module.css'
import logo from './img/logo.png'
import {Link} from 'react-scroll'
import {motion} from 'framer-motion'


const Header = () => {
  const [modal,setModal] = useState(false)
  return (
<motion.section initial={{y:'-90%'}} whileInView={{y:0}} transition={{duration:.3,ease:'easeOut',delay:3}} className={css.container}> 
    <div className={css.logoContainer}>
      <Link smooth={true} offset={0} duration={1500} to="hero"><img src={logo} className={css.logo}/></Link>
      <div className={css.textContainer}>
        <span className={css.text1}>Воронеж</span>
        <span className={css.text2}>Круглосуточно</span>
      </div>
    </div>
    <a className={css.mobileNumber}   href="tel:+79950404036">+7 (995) 040 40 36</a>
    <nav className={`${css.nav} ${modal&& css.navActive}`}>
        <div className={css.cityContainer}>
          <span className={css.city1}>Воронеж</span>
          <span className={css.city2}>Круглосуточно</span>
        </div>
        <Link spy={true} smooth={true} offset={0} duration={1500} onClick={()=>setModal(false)} to="service" className={css.Link} >Услуги</Link>
        <Link spy={true} smooth={true} offset={-50} duration={1500} onClick={()=>setModal(false)} to="price" className={css.Link} >Тарифы</Link>
        <Link spy={true} smooth={true} offset={-50} duration={1500} onClick={()=>setModal(false)} to="contacts" className={css.Link} >Контакты</Link>
        <a className={`${css.Link} ${css.num}`}  onClick={()=>setModal(false)} href="tel:+79950404036">+7 (995) 040 40 36</a>
        <motion.div initial={{opacity:0}} whileInView={{opacity:1}} className={css.close} onClick={()=>setModal(!modal)} >x</motion.div>
    </nav>
    <div className={css.butgerTrigger} onClick={()=>setModal(!modal)}>|||</div>
</motion.section>
  )
}

export default Header