import css from './Contacts.module.css'
import bac from './img/bac.png'
import circle from './img/circle.png'

const Contacts = () => {
  return (
<section className={css.container} id='contacts'>
    <img src={bac} className={css.bac}/>
    <img src={circle} className={css.circle}/>
    <h2 className={css.header}>Контакты</h2>
    <a className={css.number}   href="tel:+79950404036">+7 (995) 040 40 36</a>
    <a className={css.telegram} href="https://t.me/+79950404036?text=Здравствуйте, хочу вызвать трезвого водителя">Telegram</a>
    <a className={css.whatsapp} href="https://wa.me/79950404036?text=Здравствуйте, хочу вызвать трезвого водителя">WhatsApp</a>
</section>
  )
}

export default Contacts